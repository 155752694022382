<template>
  <div class="flex flex-row pb-8 border-b border-gray-400 border-solid header-container" v-loading.fullscreen="loading">
    <div class="flex-grow items-center flex flex-row header-container__left">
      <PageHeader
        :title="$t(`product.${mode === 'add' ? 'product_register' : 'product_edit'}`)"
        :isHideRefresh="true"
        @load:refreshList="initProduct"
      />
      <div class="ml-4">
        <el-button type="primary" :disabled="isSaveDisabled" @click="onSave" class="btn-original rounded-full w-28">{{
          $t('product.save')
        }}</el-button>
      </div>
    </div>

    <div class="items-center header-container__right">
      <el-button type="primary" size="medium" @click="backToPrevious">
        {{ $t('product.product_list') }}
      </el-button>
    </div>
  </div>

  <!-- address book table -->
  <div class="common-form-portal">
    <el-form
      :rules="rules"
      :model="formModel"
      ref="productForm"
      label-position="right"
      label-width="160px"
      class="form-container"
    >
      <div class="mt-12 pb-8 2xl:w-8/12 xl:w-11/12 lg:w-full">
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-5">
            <el-form-item prop="code" :label="$t('product.product_code')">
              <el-input v-model="formModel.code"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-7">
            <el-form-item prop="name" :label="$t('product.product_name')">
              <el-input v-model="formModel.name"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-9">
            <el-form-item prop="volume" :label="$t('product.volume')">
              <el-input v-model="formModel.volume" v-maska="'#*'"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-9">
            <el-form-item prop="material" :label="$t('product.material')">
              <el-input v-model="formModel.material"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-12 items-center gap-4 py-1">
          <div class="col-span-3">
            <el-form-item prop="dimension.height" :label="$t('product.height_cm')">
              <el-input v-model="formModel.dimension.height" v-maska="masks.dimension"></el-input>
            </el-form-item>
          </div>

          <div class="col-span-3">
            <el-form-item prop="dimension.width" :label="$t('product.width_cm')">
              <el-input v-model="formModel.dimension.width" v-maska="masks.dimension"></el-input>
            </el-form-item>
          </div>

          <div class="col-span-3">
            <el-form-item prop="dimension.depth" :label="$t('product.depth_cm')">
              <el-input v-model="formModel.dimension.depth" v-maska="masks.dimension"></el-input>
            </el-form-item>
          </div>

          <div class="col-span-3">
            <el-form-item prop="dimension.weight" :label="$t('product.weight_kg')">
              <el-input v-model="formModel.dimension.weight" v-maska="masks.dimension"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="border-b border-gray-400 border-solid"></div>

      <!-- Supplier -->
      <div class="mt-8 pb-10 2xl:w-8/12 xl:w-11/12 lg:w-full">
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-5">
            <el-form-item prop="supplier.companyName" :label="$t('product.supplier_name')">
              <el-input v-model="formModel.supplier.companyName" />
            </el-form-item>
          </div>
          <div class="col-span-4 pb-5">
            <el-button type="primary" @click="onShowSupplierDialog">{{ $t('product.select_supplier') }}</el-button>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-5">
            <el-form-item prop="supplier.country" :label="$t('product.country_region')">
              <el-select
                :placeholder="$t('barcode_type.select')"
                default-first-option
                filterable
                v-model="formModel.supplier.country"
                class="w-full"
              >
                <el-option-group
                  v-for="(group, groupKey) in countries"
                  :key="groupKey"
                  :label="$t(`countryGroup.${groupKey}`)"
                >
                  <el-option
                    v-for="country in group"
                    :key="country"
                    :value="country"
                    :label="$t(`countryList.${country}`)"
                  >
                    {{ $t(`countryList.${country}`) }}
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-12 items-center gap-4 py-1">
          <div class="col-span-6">
            <el-form-item prop="supplier.postalCode" :label="$t('product.postal_code')">
              <el-input
                v-model="formModel.supplier.postalCode"
                :placeholder="masks.zipCode.replaceAll('#', '_')"
                v-maska="masks.zipCode"
              />
            </el-form-item>
          </div>

          <div class="col-span-6">
            <el-form-item prop="supplier.prefecture" :label="$t('product.prefecture')">
              <el-input v-model="formModel.supplier.prefecture" />
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-9">
            <el-form-item prop="supplier.address1" :label="$t('product.address_1')">
              <el-input v-model="formModel.supplier.address1" />
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-9">
            <el-form-item prop="supplier.address2" :label="$t('product.address_2')">
              <el-input v-model="formModel.supplier.address2" />
            </el-form-item>
          </div>
        </div>
        <div class="grid grid-cols-9 items-center gap-4 py-1">
          <div class="col-span-9">
            <el-form-item prop="supplier.phoneNumber" :label="$t('product.phone_number')">
              <el-input
                v-model="formModel.supplier.phoneNumber"
                :placeholder="masks.phoneNumber.replaceAll('#', '_')"
                v-maska="masks.phoneNumber"
              />
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="mode === 'edit'">
        <div class="border-b border-gray-400 border-solid"></div>

        <div class="my-8 w-6/12">
          <div class="grid grid-cols-9 items-center gap-4 py-1 my-1.5">
            <div class="col-span-2 text-right">{{ $t('status') }}</div>
            <div class="col-span-7">
              <el-checkbox v-model="formModel.isActive"> {{ $t('product.active') }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>

  <el-dialog
    custom-class="el-dialog--custom"
    v-model="isShowSupplierDialog"
    width="540px"
    top="0"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="onCloseSupplierDialog"
  >
    <template #title>
      <el-form>
        <el-form-item :label="$t('product.supplier_name')">
          <el-select
            @change="onSupplierChange($event)"
            v-model="selectedSupplierName"
            :placeholder="$t('product.select_supplier')"
          >
            <el-option v-for="(value, key) in suppliers" :key="key" :label="value.companyName" :value="key">
              {{ value.companyName }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-cancel" @click="onCloseSupplierDialog">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button
            :disabled="selectedSupplierIdx === -1"
            type="primary"
            native-type="submit"
            @click="onApplySupplier"
          >
            {{ $t('apply') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import PageHeader from '@/components/common/PageHeader.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { FETCH_COUNTRIES, FETCH_PRODUCTS, FETCH_PRODUCT_SUPPLIERS } from '@/store/actions'
import { addProduct, updateProduct } from '@/utils/api'
import { DEFAULT_PRODUCT, POPULAR_COUNTRIES } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { PHONE_MASK, ZIPCODE_MASK } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IAddress, IProduct } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
@Options({
  components: { PageHeader },
  directives: { maska },
  name: 'ProductDetailPage',
})
export default class ProductDetailPage extends mixins(ValidateForm) {
  loading = false
  isShowSupplierDialog = false
  selectedSupplierIdx = -1
  selectedSupplierName = ''
  mode: 'add' | 'edit' = 'add'
  countries: {
    popularCountries: string[]
    allCountries: string[]
  } = {
    popularCountries: [],
    allCountries: [],
  }

  suppliers: IAddress[] = []
  formModel: IProduct = cloneDeep(DEFAULT_PRODUCT)

  isSaveDisabled = false

  get masks() {
    return {
      phoneNumber: PHONE_MASK[this.formModel.supplier?.country] || PHONE_MASK.DEFAULT,
      zipCode: ZIPCODE_MASK[this.formModel.supplier?.country] || '',
      dimension: '#*.#',
    }
  }

  backToPrevious() {
    this.$router.push({
      name: 'product',
      params: {
        action: 'add',
      },
    })
  }

  onShowSupplierDialog() {
    this.isShowSupplierDialog = true
  }

  onCloseSupplierDialog() {
    this.isShowSupplierDialog = false
  }

  onSupplierChange(idx: number) {
    this.selectedSupplierIdx = idx ?? -1
  }

  onApplySupplier() {
    if (this.selectedSupplierIdx !== -1) {
      this.formModel.supplier = this.suppliers[this.selectedSupplierIdx]
      this.onCloseSupplierDialog()
    }
  }

  get rules() {
    return {
      name: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      code: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      material: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      volume: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
      dimension: {
        width: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
        height: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
        depth: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
        weight: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
      },

      supplier: {
        companyName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        country: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        postalCode: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        prefecture: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        address1: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        phoneNumber: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      },
    }
  }

  displaySuccessMsg(msg: string) {
    openMessage(msg, 'success')
  }

  async doSave() {
    try {
      this.loading = true
      if (this.mode === 'add') {
        await addProduct(this.formModel).then(async () => {
          await this.$store.dispatch(FETCH_PRODUCTS)
          this.displaySuccessMsg(this.$t('product.create_success'))
          this.backToPrevious()
        })
      } else {
        if (!this.formModel.id) return

        await updateProduct(this.formModel.id, this.formModel.isActive ?? false, this.formModel).then(async () => {
          await this.$store.dispatch(FETCH_PRODUCTS)
          this.displaySuccessMsg(this.$t('product.update_success'))
          this.backToPrevious()
        })
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      this.loading = false
      this.isSaveDisabled = false
    }
  }

  async onSave() {
    this.isSaveDisabled = true
    this.$refs.productForm.validate(async (valid: string) => {
      if (valid) {
        await this.doSave()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  onChangeSupplier(idx: number) {
    if (isNaN(idx)) {
      this.suppliers.push({ ...this.formModel.supplier })
    } else {
      this.formModel.supplier = { ...this.suppliers[idx] }
    }
  }

  async created() {
    try {
      this.initProduct()
    } catch (e) {
      openMessage(e as string, 'error')
      this.backToPrevious()
    }
  }

  async initProduct() {
    this.loading = true
    const params = this.$route.params
    if (isEmpty(params)) {
      this.$router.push({
        name: 'product',
      })
    }

    this.countries.popularCountries = { ...POPULAR_COUNTRIES }
    if (!this.$store.state.profile?.isLoadCountry) {
      this.$store.dispatch(FETCH_COUNTRIES).then(() => {
        this.countries.allCountries = this.$store.state.profile?.countries
      })
    } else {
      this.countries.allCountries = this.$store.state.profile?.countries
    }

    await this.$store.dispatch(FETCH_PRODUCT_SUPPLIERS).then((res: IAddress[]) => {
      this.suppliers = res
    })
    if (params.action === 'edit') {
      this.mode = 'edit'
      this.formModel = JSON.parse(params.product)
    }
    this.loading = false
  }
}
</script>
