
import PageHeader from '@/components/common/PageHeader.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { FETCH_COUNTRIES, FETCH_PRODUCTS, FETCH_PRODUCT_SUPPLIERS } from '@/store/actions'
import { addProduct, updateProduct } from '@/utils/api'
import { DEFAULT_PRODUCT, POPULAR_COUNTRIES } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { PHONE_MASK, ZIPCODE_MASK } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IAddress, IProduct } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
@Options({
  components: { PageHeader },
  directives: { maska },
  name: 'ProductDetailPage',
})
export default class ProductDetailPage extends mixins(ValidateForm) {
  loading = false
  isShowSupplierDialog = false
  selectedSupplierIdx = -1
  selectedSupplierName = ''
  mode: 'add' | 'edit' = 'add'
  countries: {
    popularCountries: string[]
    allCountries: string[]
  } = {
    popularCountries: [],
    allCountries: [],
  }

  suppliers: IAddress[] = []
  formModel: IProduct = cloneDeep(DEFAULT_PRODUCT)

  isSaveDisabled = false

  get masks() {
    return {
      phoneNumber: PHONE_MASK[this.formModel.supplier?.country] || PHONE_MASK.DEFAULT,
      zipCode: ZIPCODE_MASK[this.formModel.supplier?.country] || '',
      dimension: '#*.#',
    }
  }

  backToPrevious() {
    this.$router.push({
      name: 'product',
      params: {
        action: 'add',
      },
    })
  }

  onShowSupplierDialog() {
    this.isShowSupplierDialog = true
  }

  onCloseSupplierDialog() {
    this.isShowSupplierDialog = false
  }

  onSupplierChange(idx: number) {
    this.selectedSupplierIdx = idx ?? -1
  }

  onApplySupplier() {
    if (this.selectedSupplierIdx !== -1) {
      this.formModel.supplier = this.suppliers[this.selectedSupplierIdx]
      this.onCloseSupplierDialog()
    }
  }

  get rules() {
    return {
      name: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      code: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      material: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      volume: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
      dimension: {
        width: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
        height: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
        depth: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
        weight: [{ required: true, validator: this.numberAndNotZeroValidate, trigger: 'blur' }],
      },

      supplier: {
        companyName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        country: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        postalCode: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        prefecture: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        address1: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
        phoneNumber: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      },
    }
  }

  displaySuccessMsg(msg: string) {
    openMessage(msg, 'success')
  }

  async doSave() {
    try {
      this.loading = true
      if (this.mode === 'add') {
        await addProduct(this.formModel).then(async () => {
          await this.$store.dispatch(FETCH_PRODUCTS)
          this.displaySuccessMsg(this.$t('product.create_success'))
          this.backToPrevious()
        })
      } else {
        if (!this.formModel.id) return

        await updateProduct(this.formModel.id, this.formModel.isActive ?? false, this.formModel).then(async () => {
          await this.$store.dispatch(FETCH_PRODUCTS)
          this.displaySuccessMsg(this.$t('product.update_success'))
          this.backToPrevious()
        })
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      this.loading = false
      this.isSaveDisabled = false
    }
  }

  async onSave() {
    this.isSaveDisabled = true
    this.$refs.productForm.validate(async (valid: string) => {
      if (valid) {
        await this.doSave()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  onChangeSupplier(idx: number) {
    if (isNaN(idx)) {
      this.suppliers.push({ ...this.formModel.supplier })
    } else {
      this.formModel.supplier = { ...this.suppliers[idx] }
    }
  }

  async created() {
    try {
      this.initProduct()
    } catch (e) {
      openMessage(e as string, 'error')
      this.backToPrevious()
    }
  }

  async initProduct() {
    this.loading = true
    const params = this.$route.params
    if (isEmpty(params)) {
      this.$router.push({
        name: 'product',
      })
    }

    this.countries.popularCountries = { ...POPULAR_COUNTRIES }
    if (!this.$store.state.profile?.isLoadCountry) {
      this.$store.dispatch(FETCH_COUNTRIES).then(() => {
        this.countries.allCountries = this.$store.state.profile?.countries
      })
    } else {
      this.countries.allCountries = this.$store.state.profile?.countries
    }

    await this.$store.dispatch(FETCH_PRODUCT_SUPPLIERS).then((res: IAddress[]) => {
      this.suppliers = res
    })
    if (params.action === 'edit') {
      this.mode = 'edit'
      this.formModel = JSON.parse(params.product)
    }
    this.loading = false
  }
}
